exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-closed-caption-services-js": () => import("./../../../src/pages/closed-caption-services.js" /* webpackChunkName: "component---src-pages-closed-caption-services-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-employment-js": () => import("./../../../src/pages/employment.js" /* webpackChunkName: "component---src-pages-employment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-caption-services-js": () => import("./../../../src/pages/live-caption-services.js" /* webpackChunkName: "component---src-pages-live-caption-services-js" */),
  "component---src-pages-transcription-services-js": () => import("./../../../src/pages/transcription-services.js" /* webpackChunkName: "component---src-pages-transcription-services-js" */),
  "component---src-pages-translation-services-js": () => import("./../../../src/pages/translation-services.js" /* webpackChunkName: "component---src-pages-translation-services-js" */)
}

